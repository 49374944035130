<template lang="pug">
div
  .row.h-100
    .col-12.pl-0.pr-5.pr-md-3.pb-0
      .container-fluid.px-0
        .row
          .d-flex.px-3.w-100
            #filters_novelties.box-list-filter__select--all.pb-5.br-1.col-2.px-0
              .bg-title-filter(v-if="show_title")
                h4 {{ t("components.novelties.title") }}
              #accordion
                div(v-for="(node, index) in novelties_index")
                  div(:id="'heading' + index")
                    li.list-select.parent-text(
                      :id="node.key"
                      :ref="'parent_node' + node.key"
                      :class="nodeClassSelector(node.key)"
                      data-toggle="collapse"
                      :data-target="dataTargetSelector(index)"
                      :aria-controls="'collapse' + index"
                      @click="updateSelectedParentKey(node.key)"
                    )
                      span {{ nodeName(node.label) }}
                      i.fas.fa-chevron-down(
                        :id="'collapse_button_' + index"
                        v-if="hasHierarchy"
                      )
                  .collapse(
                    v-if="hasHierarchy"
                    :class="collapseShowClass(node)"
                    :id="'collapse' + index"
                    :idaria-labelledby="'heading' + index"
                    data-parent="#accordion"
                  )
                    div(v-for="document_type in node.children")
                      li.list-select.parent-text.child(
                        :ref="'child_node' + document_type.key"
                        @click.stop="updateSelectedChildrenKey(document_type.key)"
                        :id="document_type + '_' + node.label"
                        :class="nodeClassChild(document_type.key)"
                      )
                        span {{ document_type.label }}
              button#novelties_subscription.btn.btn-primary.mx-3.mt-5(
                @click="goSubscribeNovelties"
                v-if="show_subscription_button"
              )
                i.far.fa-newspaper.fa-fw
                span {{ t("components.novelties.novelties_button") }}
            .col-12.col-lg-10.pb-5.novelties-links
              forms-messages-list-vue#messages.col-12.m-2.p-0(
                  :messages="messages"
                  :single_message="true"
                  :notification_message="true"
                )
              .mb-3.info-nodes(
                v-for="documentIndex in indiceDocuments"
                :key="documentIndex.key"
              )
                .novelties-links__title {{ documentIndex.label }}
                div(v-for="documentType in documentIndex.children")
                  novelty-result-vue(
                    :id="documentType.key + '_result'"
                    :documents="filteredDocumentsByDocumentType(documentType.key)"
                    :label="documentTypelabel(documentType.label)"
                    @quick-view:clicked="openQuickView"
                  )
  modal-quick-view-vue(:bus="quickViewBus")
</template>

<script>
import Vue from 'vue'
import mixins from 'common/mixins.js'
export default {
  name: 'novelties-list-vue',
  components: {
    'novelty-result-vue': () =>
      import(/* webpackChunkName: "[request]" */ 'components/NoveltyResult'),
    'forms-messages-list-vue': () =>
      import(/* webpackChunkName: "[request]" */ 'components/forms/MessagesList'),
    'modal-quick-view-vue': () =>
      import(/* webpackChunkName: "[request]" */ 'components/ModalQuickView')
  },
  data: function () {
    return {
      selectedParentKey: "",
      messages: {},
      selectedChildrenKey: "",
      totalIndexKey: "",
      quickViewBus: new Vue()
    }
  },
  mixins: [mixins.translate, mixins.requests, mixins.filters],
  props: {
    novelties_index: { type: Array, default: () => [] },
    novelties_nodes: { type: Array, default: () => [] },
    doc_type: { type: String, default: "" },
    show_title: { type: Boolean, default: true },
    show_subscription_button: { type: Boolean, default: true },
  },
  computed: {
    allDocumentTypes() {
      return I18n.t("components.novelties.all_doctypes");
    },
    hasHierarchy() {
      return this.novelties_index.some(
        (index_value) =>
          index_value.children.length != 0 && index_value.key != ""
      );
    },
    labelSelected() {
      let selectedDocuments = this.novelties_index.find((el) => el.key == this.selectedParentKey)
      return selectedDocuments ? selectedDocuments.label : '';
    },
    indiceDocuments() {
      return this.hasHierarchy
        ? this.indiceWithHierarchy()
        : this.indiceWithoutHierarchy();
    },
    isTotalIndexSelected(){
      return this.selectedParentKey === this.totalIndexKey
    }
  },
  methods: {
    documentTypelabel(label) {
      return this.hasHierarchy || this.selectedParentKey == this.totalIndexKey ? label : "";
    },
    indiceWithHierarchy() {
      const isDocumentTypeSelected = this.selectedChildrenKey !== this.selectedParentKey
      if (isDocumentTypeSelected) {
        return this.isTotalIndexSelected
          ? this.totalDocumentTypeIndex()
          : this.categoryDocumentTypeIndex();
      } else {
        return this.categoryIndex();
      }
    },
    totalDocumentTypeIndex() {
      return this.novelties_index
        .filter((indexItem) =>
          indexItem.children.some(
            (indexChildrenItem) =>
              indexChildrenItem.key.includes(this.selectedChildrenKey) &&
              indexItem.key !== this.totalIndexKey
          )
        ).map((indexChildrenItem) =>
          this.mapIndexItem(indexChildrenItem, (indexItem) =>
            indexItem.key.includes(this.selectedChildrenKey)
          )
        );
    },
    categoryDocumentTypeIndex() {
      return this.novelties_index
        .filter((indexItem) => indexItem.key === this.selectedParentKey)
        .map((indexChildrenItem) =>
          this.mapIndexItem(indexChildrenItem, (indexItem) =>
            this.selectedChildrenKey.includes(indexItem.key)
          )
        );
    },
    categoryIndex() {
      return this.isTotalIndexSelected
        ? this.novelties_index.filter((indexItem) => indexItem.key !== this.selectedParentKey)
        : this.novelties_index.filter((indexItem) => indexItem.key === this.selectedParentKey);
    },
    mapIndexItem(item, filterFn) {
      return {
        key: item.key,
        label: item.label,
        children: item.children.filter(filterFn),
      };
    },
    indiceWithoutHierarchy() {
      const isTotalSelected = this.isTotalIndexSelected;
      const filteredNovelties = this.novelties_index.filter((indexItem) =>
        isTotalSelected ? indexItem.key !== this.selectedParentKey : indexItem.key === this.selectedParentKey
      );

      if( filteredNovelties.length == 0){
        return []
      }

      const label = isTotalSelected
        ? this.allDocumentTypes
        : this.labelSelected;

      return [
        {
          children: filteredNovelties,
          key: "",
          label,
        },
      ];
    },
    collapseShowClass(node) {
      return this.expandAllNode(node) ? "show" : "";
    },
    expandAllNode(node) {
      return node.label == this.allDocumentTypes && this.hasHierarchy;
    },
    filteredDocumentsByDocumentType(document_type) {
      return this.novelties_nodes.filter((it) => it.hierarchy.includes(document_type));
    },
    goSubscribeNovelties() {
      let url = this.url_for_user_subscription_home();
      window.location.assign(url);
    },
    nodeClassChild(doctype) {
      if (this.selectedChildrenKey == doctype) return "selected";
      return "";
    },
    dataTargetSelector(index) {
      return "#collapse" + index;
    },
    nodeClassSelector(node_id) {
      if (node_id == this.selectedParentKey) {
        return this.hasHierarchy ? "active" : "active_no_hierarchy";
      }
      return "";
    },
    nodeName(node_name) {
      return this.node_name == ""
        ? this.t("components.novelties.all_novelties_title")
        : node_name;
    },
    updateSelectedChildrenKey(selectedDocumentType) {
      this.selectedChildrenKey = selectedDocumentType;
    },
    updateSelectedParentKey(selected) {
      this.updateSelectedChildrenKey(selected);
      this.selectedParentKey = selected;
    },
    createEmptyMessage(){
      this.messages = {
        no_result_message:
          I18n.t("components.novelties.no_results") + this.doc_type + ".",
        }
    },
    openQuickView(id) {
      this.quickViewBus.$emit('document_show', String(id), '')
    }
  },
  watch: {
    indiceDocuments(new_document_index){
      if(new_document_index.length == 0){
        this.createEmptyMessage()
      } else {
        this.messages = {}
      }
    }
  },
  mounted() {
    if(this.novelties_nodes.length == 0){
      this.createEmptyMessage()
    }
    if(this.doc_type != ''){
      let docType = this.doc_type.toLowerCase()
      if(this.hasHierarchy){
        this.selectedChildrenKey = docType
      }else{
        this.selectedParentKey = docType
      }
    }
  },
};
</script>
