<template lang="pug">
  .row.justify-content-center
    #forms_request_form.col-12(v-if="showForm")
      loader-vue(:visible='sendingRequest')
      p.forms_Request__title_Form {{ t('forms_request.form_title') }}
      form
        .row.mt-3
          .col-12.col-lg-6.mb-3.mb-lg-0
            input#input_name.form-control(type='text' :placeholder='namePlaceholder' v-model='name')
          .col-12.col-lg-6
            input#input_last_name.form-control(type='text' :placeholder='lastNamePlaceholder' v-model='lastName')
        .row.mt-3
          .col-12.col-lg-6.mb-3.mb-lg-0
            input#input_city.form-control(type='text' :placeholder='cityPlaceholder' v-model='city')
          .col-12.col-lg-6
            input#input_zip_code.form-control(type='zip' :placeholder='zipCodePlaceholder' v-model='zipCode')
        .row.mt-3
          .col-12.col-lg-6.mb-3.mb-lg-0
            input#input_phone.form-control(type='tel' :placeholder='phonePlaceholder' v-model='phone')
          .col-12.col-lg-6
            input#input_email.form-control(type='email' :placeholder='emailPlaceholder' v-model='email')
        .row.mt-3(v-if="tolgeoIsMex")
          .col-12.col-lg-6.mb-3.mb-lg-0
            select#input_matter.form-control(v-model='matter')
              option(value='') {{ matterPlaceholder }}
              option(v-for="matter in matters" :key="matter" :value="matter") {{ matter }}
          .col-12.col-lg-6
            select#input_federative_entity.form-control(:placeholder='federativeEntityPlaceholder' v-model='federativeEntity')
              option(value='') {{ federativeEntityPlaceholder }}
              option(v-for="federativeEntity in federativeEntities" :key="federativeEntity" :value="federativeEntity") {{ federativeEntity }}
        .row.mt-3(v-if="tolgeoIsMex")
          .col-12
            small(v-html="t('forms_request.instructions')")
        .row.mt-3
          .col-12
            textarea#input_text.form-control(:placeholder='textPlaceholder' :maxlength="textMaxLength" v-model='text' rows='5')
            forms-voice-button-vue(
             :maxlength="textMaxLength"
             @update-text="handleUpdatedText"
            )
        .row.mt-3
          .col-12
            forms-lopd-text-vue
            forms-messages-list-vue(:messages='errors' :title='errorsTitle')
        .row.mt-3
          .col-12.d-flex.justify-content-end
            button#submit_forms_request_form.btn.btn-primary.submit(@click='submitForm')
              | {{ t('forms_request.send') }}
    #request_response_container.login_form_per(v-else)
      .alert.alert-success(v-if="!requestError")
        p.message-success(v-html="t('forms_request.submit_message')")
      .alert.alert-danger(v-else)
        p.message-error(v-html="t('forms_request.submit_message_error')")
      .d-flex.justify-content-center
        a#back_to_form.btn.btn-primary.submit(href='#' @click='() => showForm = !showForm') {{ t('forms_request.back_form') }}
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import mixins from 'common/mixins'
import constants from 'common/constants.js'
import ApiClient from 'common/ApiClient'
import Validator from 'common/Validator'

export default {
  mixins: [mixins.translate, mixins.requests],
  name: 'forms-request-form-vue',
  components: {
    'forms-lopd-text-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/LopdText'),
    'forms-messages-list-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/MessagesList'),
    'loader-vue': () => import(/* webpackChunkName: "[request]" */ 'components/Loader'),
    'forms-voice-button-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/VoiceButton')
  },
  data() {
    return {
      name: '',
      lastName: '',
      city: '',
      zipCode: '',
      phone: '',
      email: '',
      text: '',
      matter: '',
      matters: [],
      federativeEntity: '',
      contactSource: 'Solicitud formularios',
      sendingRequest: false,
      showForm: true,
      errors: {},
      requestError: false,
      namePlaceholder: I18n.t('forms_request.placeholders.name'),
      lastNamePlaceholder: I18n.t('forms_request.placeholders.last_name'),
      cityPlaceholder: I18n.t('forms_request.placeholders.city'),
      zipCodePlaceholder: I18n.t('forms_request.placeholders.zip_code'),
      phonePlaceholder: I18n.t('forms_request.placeholders.phone'),
      emailPlaceholder: I18n.t('forms_request.placeholders.email'),
      textPlaceholder: I18n.t('forms_request.placeholders.text'),
      matterPlaceholder: I18n.t('forms_request.placeholders.matter'),
      federativeEntityPlaceholder: I18n.t('forms_request.placeholders.federative_entity'),
      instructions: I18n.t('forms_request.instructions'),
      errorsTitle: I18n.t('forms_request.errors_title'),
      phoneMinLength: constants.PHONE_MIN_LENGTH,
      phoneMaxLength: constants.PHONE_MAX_LENGTH,
      textMaxLength: constants.TEXTAREA_MAX_LENGTH
    }
  },
  created() {
    if (gon.forms_request_matters) {
      this.matters = gon.forms_request_matters
    }
    if (gon.forms_request_federative_entities) {
      this.federativeEntities = gon.forms_request_federative_entities
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      if (this.validateForm()) {
        this.sendData()
      }
    },
    validateForm() {
      let validatorParams = [
        {
          fields: I18n.t('forms_request.fields.name'),
          values: this.name,
          validates: 'filled',
        },
        {
          fields: I18n.t('forms_request.fields.last_name'),
          values: this.lastName,
          validates: 'filled',
        },
        {
          fields: I18n.t('forms_request.fields.city'),
          values: this.city,
          validates: 'filled',
        },
        {
          fields: I18n.t('forms_request.fields.zip_code'),
          values: this.zipCode,
          validates: 'filled',
        },
        {
          fields: I18n.t('forms_request.fields.phone'),
          values: this.phone,
          params: [this.phoneMinLength, this.phoneMaxLength],
          validates: 'length_between',
        },
        {
          fields: I18n.t('forms_request.fields.email'),
          values: this.email,
          validates: 'email_format',
        }
      ]

      if (this.tolgeoIsMex) {
        validatorParams.push(
          {
          fields: I18n.t('forms_request.fields.matter'),
          values: this.matter,
          validates: 'filled',
        },
        {
          fields: I18n.t('forms_request.fields.federative_entity'),
          values: this.federativeEntity,
          validates: 'filled',
        })
      }

      validatorParams.push(
        {
          fields: I18n.t('forms_request.fields.text'),
          values: this.text,
          validates: 'filled',
        },
        {
          fields: I18n.t('forms_request.fields.check_info'),
          values: this.lopd_privacy,
          validates: 'checked',
        }
      )
      let validator = new Validator(validatorParams).do()

      if (!validator.success) {
        this.setErrors(validator.messages)
        return false
      }
      return true
    },
    async sendData() {
      this.sendingRequest = true
      let payload = {
        /* eslint-disable camelcase */
        nombre: this.name,
        apellidos: this.lastName,
        poblacion: this.city,
        codigo_postal: this.zipCode,
        telefono: this.phone,
        email: this.email,
        matter: this.matter,
        federative_entity: this.federativeEntity,
        texto: this.text,
        origen: this.contactSource,
        lopd_privacidad: this.lopd_privacy,
        lopd_grupo: this.lopd_group,
        lopd_comercial: this.lopd_publicity
        /* eslint-enable camelcase */
      }

      await this.saveCommercialContact(payload)
      if (this.saveCommercialContactFailed) {
        this.requestFailed()
        return
      }

      const sendMailResponse = await this.sendFormsRequestEmail(payload)
      if (sendMailResponse.failed) {
        this.requestFailed()
        return
      }

      this.requestSuccess()
    },
    requestSuccess() {
      this.requestError = false
      this.clearForm()
      this.hideForm()
    },
    requestFailed() {
      this.requestError = true
      this.sendingRequest = false
      this.hideForm()
    },
    async sendFormsRequestEmail(payload) {
      const api = new ApiClient()
      const response = await api.post(gon.url_for.forms_request_send_mail, payload)

      return response
    },
    setErrors(errors) {
      this.errors = errors
    },
    clearForm() {
      this.name = ''
      this.lastName = ''
      this.city = ''
      this.zipCode = ''
      this.phone = ''
      this.email = ''
      this.text = ''
      this.matter = ''
      this.federativeEntity = ''
      this.errors = {}
      this.clearLopd()
    },
    hideForm() {
      this.sendingRequest = false
      this.showForm = false
    },
    handleUpdatedText(processedText) {
      this.text = processedText
    },
    ...mapMutations('lopd_texts', ['clearLopd']),
    ...mapActions('commercial_contact', { saveCommercialContact: 'saveRequest'})
  },
  computed: {
    tolgeoIsMex(){
      return gon.app_tolgeo == 'mex'
    },
    ...mapState('lopd_texts', [
      'lopd_privacy',
      'lopd_group',
      'lopd_publicity'
    ]),
    ...mapState('commercial_contact', { saveCommercialContactFailed: 'saveRequestFailed'})
  },
}
</script>
