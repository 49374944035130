<template>
<div class="row mx-0">
  <button class="btn btn-sidebar-collapse"  v-if="collapsePanel" type="button" @click="expandPanel">
    <i class="fas fa-angle-double-right"></i>
  </button>
  <div class="col-12 d-flex flex-column pl-5">
    <sofia-toolbar-vue
      :options="toolbar_options"
      :file_name="file_name"
      :uploading_file="uploading"
      :allowed_file_types="allowed_file_types"
      :document_modified="document_modified"
      :document_header_options="document_header_options"
      :document_id="document_id"
      @save_document:clicked="handle_save_document_clicked"
    />
    <documents-document-texts-vue
      :text_fields="document_header_options.body_fields"
    />
  </div>
  <div :class="{ row: !sofiaEnabled }" class="col-12 pl-5">
    <div :class="{ col: !sofiaEnabled }">
      <div id="tirantcloud-editor" v-once />
      <modal-quick-view-vue ref="document_extracto" :copy-buttons-visible="true"/>
      <confirmation-modal-vue
        ref="file_change_confirmation_modal"
        :title="t('tirantcloud.title')"
        @confirmation:clicked="handle_confirmation_clicked"
      >
        {{ t("sofia.sofia_uploader.confirm") }}
      </confirmation-modal-vue>
      <modal-folder-save-vue
        :file_name="file_name_without_extension"
        :file_extension="file_extension"
        :document_name="document_name"
        @save_document_on_folder:triggered="handle_save_document_on_folder_triggered"
        ref="modal_folder_save"
      />
      <modal-vue
        modal_id="error_modal"
        :visible.sync="upload_error"
        :content_loaded="true"
        @accept_button:clicked="upload_error_clear"
      >
        <template v-slot:header>{{ upload_error_title }}</template>
        <template v-slot:body>{{ upload_error_message }}</template>
      </modal-vue>
    </div>

    <div id="form-sofia-premium" class="px-0 col-3" v-if="show_sofia_premium_form">
      <div class="form_sofia_premium_editor" :class="{ sofia_shadow: shadow_animation }" >
        <sofia-premium-form-vue
          :close_form_button="close_form_button"
          :shadow_animation="form_properties"
          info_link_position="body"
          @form_close_button:clicked="handle_premium_form_close_clicked"
        />
      </div>
    </div>
  </div>
    <user-subscription-modal-login-vue ref="user_subscription_login_modal" @login:success="handle_login_sucess"/>
  <sofia-filter-modals-vue
    ref="sofia-filter-modals"
    @confirm_filter_values="confirmFilterValues"
    :sofia-filters="sofiaFilters"
  />
</div>

</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex"
import mixins from "common/mixins";
import tirantcloud from "common/mixins/tirantcloud";
import token_formatter from 'common/token_formatter.js';
import SofiaUploaderMixin from "common/mixins/uploader_mixin";

export default {
  name: "sofia-file-analyzer-vue",
  mixins: [mixins.requests, mixins.translate, tirantcloud, SofiaUploaderMixin],
  props: {
    file_path: { type: String, default: "" },
    upload_enabled: { type: Boolean, default: true },
    document_id: { type: String, default: "" },
    document_domain: { type: String, default: "" },
    document_name: { type: String, default: "" },
    analytics_use_title: { type: Boolean, default: true },
    close_form_button: { type: Boolean, default: false },
    toolbar_options: { type: Array, default: () => ["file_name", "upload"] },
    favourites_enabled: { type: Boolean, default: false },
    formulario_view: { type: Boolean, default: false },
    force_ai: { type: Boolean, default: false },
    searched_words: { type: Array, default: () => [] },
    document_header_options: { type: Object, default: () => { return {} } },
    document_lang: { type: String, default: "es"},
    show_new_document_menu: { type: Boolean, default: true }
  },
  components: {
    "modal-folder-save-vue": () => import(/* webpackChunkName: "[request]" */ "components/ModalFolderSave"),
    "modal-quick-view-vue": () => import(/* webpackChunkName: "[request]" */ "components/ModalQuickView.vue"),
    "confirmation-modal-vue": () => import(/* webpackChunkName: "[request]" */ "components/ConfirmationModal"),
    "sofia-header-vue": () => import(/* webpackChunkName: "[request]" */ "components/sofia/Header"),
    "sofia-premium-form-vue": () => import(/* webpackChunkName: "[request]" */ "components/sofia/PremiumForm.vue"),
    "sofia-toolbar-vue": () => import(/* webpackChunkName: "[request]" */ "components/sofia/Toolbar"),
    "user-subscription-modal-login-vue": () =>import( /* webpackChunkName: "[request]" */ "components/user_subscription/ModalLogin"),
    'documents-document-texts-vue': () => import(/* webpackChunkName: "[request]" */ "../documents/DocumentTexts.vue")
  },
  data() {
    return {
      error_file: false,
      shadow_animation: false,
      show_shadow: false,
      storage_type: "temp",
      allowed_file_types: ".pdf,.txt,.rtf,.doc,.docx,.odt,.html,.ott,.sxw",
      document_modified: false,
      premium_form_visible: true
    }
  },

  computed: {
    file_extension() {
      if (this.file_path.includes(".")) {
        return "." + this.file_path.split(".").slice(-1).toString();
      }
      return ""
    },
    file_name() {
      return this.file_path.split("/").slice(-1).toString();
    },
    file_name_without_extension() {
      var file_name = this.file_name.replace(this.file_extension, "");
      return file_name;
    },
    folder_path() {
      return this.file_path.replace(/[^\/]*$/, "");
    },
    upload_error_title() {
      return I18n.t("components.sofia_file_analyzer.error_upload.title");
    },
    upload_error_message() {
      return (
        I18n.t("components.sofia_file_analyzer.error_upload.message") +
        ": " +
        this.current_file.name
      );
    },
    reject_docids() {
      return this.document_id ? [Number(this.document_id)] : null
    },
    form_properties() {
      return this.shadow_animation;
    },
    show_sofia_premium_form() {
      return !this.sofiaEnabled && this.premium_form_visible
    },
    cleaned_searches() {
      if (this.searched_words == undefined || !this.formulario_view)
        return []

      var pre_clean = this.searched_words.flat().map(function(value){
        return value.split(' ');
      }).flat();

      return token_formatter.format_and_split_tokens(new Set(pre_clean));
    },
    sofiaEnabled() {
      return this.userPermissionModules.includes('sofia')
    },
    ...mapState('sofia', [
      'upload_error',
      'save_in_folder_error',
      'uploading',
      'new_file_path',
      'folder_file_path',
      'current_file'
    ]),
    ...mapState("hub_search", ["collapsePanel"]),
    ...mapGetters(["has_user_subscription"])
  },

  watch: {
    new_file_path(file_path) {
      let url = this.format_uri(gon.url_for.sofia_file_analyzer, { file_path })
      window.location.assign(url)
    },
    save_in_folder_error(failed) {
      if(failed) this.$refs.modal_folder_save.save_error()
      this.save_in_folder_error_clear()
    },
    folder_file_path(redirectPath) {
      let url = this.format_uri(gon.url_for.user_subscriptions_tirantcloud, { redirectPath })
      window.location.assign(url)
    },
    current_file() {
      this.$refs.file_change_confirmation_modal.show()
    }
  },

  mounted() {
    let options = this.get_generator_options();
    const normalizedExtension = this.file_extension.toLowerCase()

    if (normalizedExtension == ".pdf") {
      this.tirantCloud.generatePdfViewer(options);
      return;
    }

    this.editor = this.tirantCloud.generateEditor(
      Object.assign(options, {
        onModifiedChanged: (status) => {
          this.document_modified = status;
        },
        preventExitWhenModified: true,
      })
    );
  },
  methods: {
    show_document(document_id, document_type) {
      this.$refs.document_extracto.show_document(document_id, document_type)
    },
    async save_document(params) {
      if (this.editor) await this.editor.save()

      this.save_in_folder(params)
    },
    toggle_premium_form() {
      if (this.premium_form_visible & !this.shadow_animation) {
        this.shadow_animation = true;
        setTimeout(() => {
          this.shadow_animation = false;
        }, 2000);
      } else {
        this.premium_form_visible = true
      }
    },
    get_generator_options() {
      return {
        selector: 'tirantcloud-editor',
        path: this.file_path,
        title: this.document_name,
        documentTitleVisible: false,
        breadcrumbVisible: false,
        renameEnabled: false,
        showNewDocumentMenu: this.show_new_document_menu,
        onSofiaDocumentClick: this.show_document,
        analyticsEnabled: this.sofiaEnabled,
        analyticsUseTitle: this.analytics_use_title,
        rootPath: this.folder_path,
        favouritesEnabled: this.favourites_enabled,
        rejectIds: this.reject_docids,
        initialDomain: this.document_domain,
        forceAiGenView: this.force_ai,
        formView: this.formulario_view,
        searchedWords: this.cleaned_searches,
        documentLang: this.document_lang,
        onNewDocumentClick: this.on_new_document_click,
        onSaveDocumentClick: this.on_save_document_click
      }
    },
    handle_confirmation_clicked({ confirmed }) {
      if(!confirmed) return
      this.upload(this.current_file)
      this.$refs.file_change_confirmation_modal.hide()
    },
    handle_save_document_clicked() {
      if (!this.sofiaEnabled) {
        this.toggle_premium_form()
        return
      }
      if (!this.has_user_subscription) {
        this.showLoginForm()
        return
      }
      this.$refs.modal_folder_save.show()
    },
    handle_login_sucess() {
      this.$refs.modal_folder_save.reset_modal()
      this.$refs.modal_folder_save.reload()
      this.$refs.modal_folder_save.show()
    },
    showLoginForm() {
      this.$refs.user_subscription_login_modal.show()
    },
    handle_save_document_on_folder_triggered(folder_path, input_path) {
      const new_file_path = folder_path + input_path;

      if (!new_file_path) return

      this.save_document({
        document_id: this.document_id,
        old_file_path: this.file_path,
        folder_path,
        input_path
      })
    },
    handle_premium_form_close_clicked() {
      this.premium_form_visible = false
    },
    expandPanel(){
      this.setCollapsePanel(false)
    },
    on_new_document_click() {
      this.new_document('')
    },
    on_save_document_click() {
      this.handle_save_document_clicked()
    },
    ...mapActions('sofia', ['upload', 'save_in_folder']),
    ...mapMutations('sofia', [
      'upload_error_clear',
      'save_in_folder_error_clear'
    ]),
    ...mapMutations('hub_search', ['setCollapsePanel'])

  }
}
</script>
