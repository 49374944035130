<template lang="pug">
div
  span#title.novelties-links__subtitle {{ label }}
  div.d-flex.align-items-center.ml-2(v-for="document in documents")
    button.novelties-button(@click="goToDocument(document.document_id)")
      i.fas.fa-circle
      span.novelties_document {{documentTitle(document) }}
    a.ml-2.badge.badge-pill.badge-primary(
      href="#"
      @click="openQuickView(document.document_id)"
      data-cy="quick-view-button"
    )
      | {{ t(`components.documents.item.vista_rapida_${document.search_type}`, { defaultValue: t('components.documents.item.vista_rapida') } ) }}
</template>

<script>
import mixins from 'common/mixins.js'

export default {
  name: 'novelty-result-vue',
  props: {
    documents: { type: Array, default: () => [] },
    label: { type: String, default: '' }
  },
  mixins: [mixins.requests, mixins.translate],
  methods: {
    goToDocument(id) {
      let url = this.url_for_grails_documento_show(id)
      window.location.assign(url)
    },
    documentTitle(document) {
      return document.document_title && document.num_tol ? `${document.document_title} (${document.num_tol})` : ' '
    },
    openQuickView(id) {
      this.$emit('quick-view:clicked', id, '')
    }
  }
}
</script>
