<template lang="pug">
.row.justify-content-center
  #documental_assistant_form.col-12(v-if="showForm")
    loader-vue(:visible='sendingRequest')
    p.documental_Assistant__title_Form {{ t('documental_assistant.form_title') }}
    form
      .row.mt-3
        .col-12.col-lg-6.mb-3.mb-lg-0
          input#input_name.form-control(type='text' :placeholder='namePlaceholder' v-model='name')
        .col-12.col-lg-6
          input#input_last_name.form-control(type='text' :placeholder='lastNamePlaceholder' v-model='lastName')
      .row.mt-3
        .col-12(:class="cityClasses")
          input#input_city.form-control(type='text' :placeholder='cityPlaceholder' v-model='city')
        .col-12.col-lg-6(v-if="zipCodeEnabled")
          input#input_zip_code.form-control(
            type='zip'
            :placeholder='zipCodePlaceholder'
            v-model='zipCode'
            data-cy='zip_code_input'
          )
      .row.mt-3
        .col-12.col-lg-6.mb-3.mb-lg-0
          input#input_phone.form-control(type='tel' :placeholder='phonePlaceholder' v-model='phone')
        .col-12.col-lg-6
          input#input_email.form-control(type='email' :placeholder='emailPlaceholder' v-model='email')
      .row.mt-3
        .col-12
          textarea#input_text.form-control(:placeholder='textPlaceholder' :maxlength="textMaxLength" v-model='text' rows='5')
          forms-voice-button-vue(
            :maxlength="textMaxLength"
            @update-text="handleUpdatedText"
          )
      .row.mt-3
        .col-12
          forms-lopd-text-vue
          forms-messages-list-vue(:messages='errors' :title='errorsTitle')
      .row.mt-3
        .col-12.d-flex.justify-content-end
          button#submit_documental_assistant_form.btn.btn-primary.submit(@click='submitForm')
            | {{ t('documental_assistant.send') }}
  #request_response_container.login_form_per(v-else)
    .alert.alert-success(v-if="!requestError")
      p.message-success(v-html="t('documental_assistant.submit_message')")
    .alert.alert-danger(v-else)
      p.message-error(v-html="t('documental_assistant.submit_message_error')")
    .d-flex.justify-content-center
      a#back_to_form.btn.btn-primary.submit(href='#' @click='toggleForm') {{ t('documental_assistant.back_form') }}
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import mixins from 'common/mixins'
import constants from 'common/constants.js'
import ApiClient from 'common/ApiClient'
import Validator from 'common/Validator'

export default {
  mixins: [mixins.translate, mixins.requests],
  name: 'documental-assistant-form-vue',
  components: {
    'forms-lopd-text-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/LopdText'),
    'forms-messages-list-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/MessagesList'),
    'loader-vue': () => import(/* webpackChunkName: "[request]" */ 'components/Loader'),
    'forms-voice-button-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/VoiceButton')
  },
  data() {
    return {
      name: '',
      lastName: '',
      city: '',
      zipCode: '',
      phone: '',
      email: '',
      text: '',
      contactSource: 'Asistente documental',
      sendingRequest: false,
      showForm: true,
      errors: {},
      requestError: false,
      namePlaceholder: I18n.t('documental_assistant.placeholders.name'),
      lastNamePlaceholder: I18n.t('documental_assistant.placeholders.last_name'),
      cityPlaceholder: I18n.t('documental_assistant.placeholders.city'),
      zipCodePlaceholder: I18n.t('documental_assistant.placeholders.zip_code'),
      phonePlaceholder: I18n.t('documental_assistant.placeholders.phone'),
      emailPlaceholder: I18n.t('documental_assistant.placeholders.email'),
      textPlaceholder: I18n.t('documental_assistant.placeholders.text'),
      errorsTitle: I18n.t('documental_assistant.errors_title'),
      phoneMinLength: constants.PHONE_MIN_LENGTH,
      phoneMaxLength: constants.PHONE_MAX_LENGTH,
      textMaxLength: constants.TEXTAREA_MAX_LENGTH
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      if (this.validateForm()) {
        this.sendData()
      }
    },
    validateForm() {
      let validatorParams = [
        {
          fields: I18n.t('documental_assistant.fields.name'),
          values: this.name,
          validates: 'filled',
        },
        {
          fields: I18n.t('documental_assistant.fields.last_name'),
          values: this.lastName,
          validates: 'filled',
        },
        {
          fields: I18n.t('documental_assistant.fields.city'),
          values: this.city,
          validates: 'filled',
        },
        {
          fields: I18n.t('documental_assistant.fields.phone'),
          values: this.phone,
          params: [this.phoneMinLength, this.phoneMaxLength],
          validates: 'length_between',
        },
        {
          fields: I18n.t('documental_assistant.fields.email'),
          values: this.email,
          validates: 'email_format',
        },
        {
          fields: I18n.t('documental_assistant.fields.text'),
          values: this.text,
          validates: 'filled',
        },
        {
          fields: I18n.t('documental_assistant.fields.check_info'),
          values: this.lopd_privacy,
          validates: 'checked',
        }
      ]

      if (this.zipCodeEnabled)
        validatorParams.push({
          fields: I18n.t('documental_assistant.fields.zip_code'),
          values: this.zipCode,
          validates: 'filled',
        })

      let validator = new Validator(validatorParams).do()

      if (!validator.success) {
        this.setErrors(validator.messages)
        return false
      }
      return true
    },
    async sendData() {
      this.sendingRequest = true
      let payload = {
        /* eslint-disable camelcase */
        nombre: this.name,
        apellidos: this.lastName,
        poblacion: this.city,
        codigo_postal: this.zipCode,
        telefono: this.phone,
        email: this.email,
        texto: this.text,
        origen: this.contactSource,
        lopd_privacidad: this.lopd_privacy,
        lopd_grupo: this.lopd_group,
        lopd_comercial: this.lopd_publicity
        /* eslint-enable camelcase */
      }

      await this.saveCommercialContact(payload)
      if (this.saveCommercialContactFailed) {
        this.requestFailed()
        return
      }

      const sendMailResponse = await this.sendDocumentalAssistantEmail(payload)
      if (sendMailResponse.failed) {
        this.requestFailed()
        return
      }

      this.requestSuccess()
    },
    requestSuccess() {
      this.requestError = false
      this.clearForm()
      this.hideForm()
    },
    requestFailed() {
      this.requestError = true
      this.sendingRequest = false
      this.hideForm()
    },
    async sendDocumentalAssistantEmail(payload) {
      const api = new ApiClient()
      const response = await api.post(gon.url_for.documental_assistant_send_mail, payload)

      return response
    },
    setErrors(errors) {
      this.errors = errors
    },
    clearForm() {
      this.name = ''
      this.lastName = ''
      this.city = ''
      this.zipCode = ''
      this.phone = ''
      this.email = ''
      this.text = ''
      this.errors = {}
      this.clearLopd()
    },
    hideForm() {
      this.sendingRequest = false
      this.showForm = false
    },
    handleUpdatedText(processedText) {
      this.text = processedText
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    ...mapMutations('lopd_texts', ['clearLopd']),
    ...mapActions('commercial_contact', { saveCommercialContact: 'saveRequest'})
  },
  computed: {
    ...mapState('lopd_texts', [
      'lopd_privacy',
      'lopd_group',
      'lopd_publicity'
    ]),
    ...mapState('commercial_contact', { saveCommercialContactFailed: 'saveRequestFailed'}),
    zipCodeEnabled() {
      return gon.paisiso != 'cri'
    },
    cityClasses() {
      return this.zipCodeEnabled ? 'col-lg-6 mb-3 mb-lg-0' : ''
    }
  },
}
</script>
